import React from "react";
import Layout from "../components/layout";
import ServiceFooter from "../components/ServiceFooter";
import { imagePathURL } from "../utils/JSONData";
import "../Assets/styles/services.scss";
import { Row, Col } from "react-bootstrap";
import dotImg from "../Assets/images/Home/dotImg.png";
import { graphql } from "gatsby";
import Fade from 'react-reveal/Fade';
import Pulse from 'react-reveal/Pulse';
import Footer from "../components/Footer/Footer";
import Scrolltop from "../components/Scrolltotop/scrolltop";

const ServicesScreen = ({ data }) => {
  const { allServices, allServiceDevelopmentServices, allTechnologyStacks, allGetInTouchs } = data
  return (
    <>
      <Layout>
        <div className="service_react_group">
          <Row>
            <Col sm={12} md={8} className="m-auto">
              <div>
                <div className="service_react_title">
                  {allServices.nodes[0].data.attributes.title}
                </div>
                <div className="service_react_subtitle">
                  {allServices.nodes[0].data.attributes.description1}
                </div>
                <div className="service_react_subtitle2">
                  {allServices.nodes[0].data.attributes.description2}
                </div>
                <div className="service_react_button">
                  <button className="service_freequote">GET A FREE QUOTE</button>
                  <button className="service_ourwork">OUR WORK</button>
                </div>
              </div>
            </Col>
            <Col sm={12} md={4}>
              <div>
                <Pulse>
                  <img src={imagePathURL + allServices.nodes[0].data.attributes.image.data.attributes.url} alt="react-logo" />
                </Pulse>
              </div>
            </Col>
          </Row>
        </div>
        <div className="position-relative">
          <div className="service_react_title px-4 text-center">
            {allServices.nodes[0].data.attributes.section1Title}
          </div>
          <div className="service_react_subtitle px-4 text-center">
            {allServices.nodes[0].data.attributes.section1Desc}
          </div>
          <div className="service_cross-platform">Cross Platform</div>
          <img src={dotImg} alt="dot" className="service_dot-image" />
          <div className="service_RN_develop">
            {allServiceDevelopmentServices.nodes[0].data.map((item, i) => {
              return (
                <Fade top>
                  <div className="service_develop_group" key={i}>
                    <img className="service_developimg" src={imagePathURL + item?.attributes.image.data.attributes.url} alt="searvice" />
                    <div className="service_developtitle">{item.attributes.title}</div>
                    <div className="service_developdetails">{item.attributes?.description}</div>
                  </div>
                </Fade>
              );
            })}
          </div>
        </div>
        <div className="service_quote_group">
          <div className="service_title_group">
            <div className="service_quote_title">
              {allGetInTouchs.nodes[0].data.attributes.title}
            </div>
            <div className="service_quote_details">
              {allGetInTouchs.nodes[0].data.attributes.description}
            </div>
          </div>
          <div>
            <button className="service_quote_button">
              {allGetInTouchs.nodes[0].data.attributes.btnTxt}
            </button>
          </div>
        </div>
        <div className="service_trofi_content">
          <div className="service_react_title text-center mx-2">
            {allServices.nodes[0].data.attributes.section2Title}
          </div>
          <div
            className="service_react_subtitle text-center mx-2"
          >
            {allServices.nodes[0].data.attributes.section2Subtitle}
          </div>
          <div className="service_trofi_subcontent">
            <Row>
              <Col sm={12} md={6} className="m-auto">
                <div className="service_trofi_title_content">
                  <div className="service_trofi_title">
                    {allServices.nodes[0].data.attributes.section2ContentTitle}
                  </div>
                  <div className="service_developdetails">
                    {allServices.nodes[0].data.attributes.section2ContentSubTitle}
                  </div>
                  <div className="service_trofi_details">
                    {allServices.nodes[0].data.attributes.section2ContentDesc}
                  </div>
                  <Fade top>
                    <button className="service_viewCaseStudy"> {allServices.nodes[0].data.attributes.section2ContentBtnTxt}</button>
                  </Fade>
                </div>
              </Col>
              <Col sm={12} md={6} className="m-auto text-center">
                <div>
                  <Pulse>
                    <img
                      className="service_trofiDevice_img"
                      src={imagePathURL + allServices.nodes[0].data.attributes.section2ContentImage.data.attributes.url}
                      alt="trofi"
                    />
                  </Pulse>
                </div>
              </Col>
            </Row>
          </div>
          <div className="service_react_title text-center m-auto">
            <span style={{ display: "inline-block" }}>

              Technology Stack
              <div style={{ display: "flex", justifyContent: "center" }} >
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "25%", marginRight: "10px" }}></div>
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "3%" }}></div>
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "25%", marginLeft: "10px" }}> </div>
              </div>
            </span>
          </div>
          {/* <div>
            <div className="service_line1"></div>
          </div> */}
          <div className="service_stack_content">
            {allTechnologyStacks.nodes[0].data.map((item, i) => {
              return (
                <Fade top>
                <div className="service_stack_group" key={i}>
                  <div className="service_stack_title_content">
                    <img src={imagePathURL + item.attributes?.image.data.attributes.url} alt="technology" />
                    <div className="service_stack_title">{item.attributes.title}</div>
                  </div>
                  <div className="service_arr_group">
                    {item.attributes.technologyName?.replace(/\[|\]/g, '').split(',').map((d, i) => {
                      return (
                        <div
                          className="service_quote_details"
                          style={{
                            margin: "auto",
                            display: "flex",
                            alignItems: "center",
                            fontSize: '14px'
                          }}
                          key={i}
                        >
                          <div className="service_bullets"></div>
                          {d}
                        </div>
                      );
                    })}
                  </div>
                </div>
                </Fade>
              );
            })}
          </div>
        </div>
        {/* <ServiceFooter /> */}
        <Footer />
        <Scrolltop />
      </Layout>
    </>
  );
};
export const query = graphql`
  {
    allServices {
      nodes {
        data {
          attributes {
            description1
            description2
            image {
              data {
                attributes {
                  url
                }
              }
            }
            section1Desc
            section1Title
            section2ContentBtnTxt
            section2ContentDesc
            section2ContentImage {
              data {
                attributes {
                  url
                }
              }
            }
            section2ContentSubTitle
            section2ContentTitle
            section2Subtitle
            section2Title
            title
          }
        }
      }
    }

    allServiceDevelopmentServices {
      nodes {
        data {
          attributes {
            description
            title
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }

    allTechnologyStacks {
      nodes {
        data {
          attributes {
            technologyName
            title
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }

    allGetInTouchs {
      nodes {
        data {
          attributes {
            title
            description
            btnTxt
          }
        }
      }
    }
  }
`;
export default ServicesScreen;