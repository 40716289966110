import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import footerLogo from "../../Assets/images/Footer/footer_logo.png";
// import PhoneCall from "../../Assets/images/Footer/PhoneCall.svg";
import { imagePathURL } from "../../utils/JSONData";
import { navigate, graphql, useStaticQuery } from "gatsby"
import "./Footer.scss";
import { Images } from "../../Assets/images/images";

const Footer = () => {
  const { allFooterDescriptions, allLocations, allSocials } = useStaticQuery(query);

  const openEmail = (index) => {
    if (index === 1) navigate("mailto:contact@madhavtechno.com")
    if (index === 0) navigate("tel:+91-960-129-2338")
  }

  const handleLinkClick = () => {
    window.open("https://www.linkedin.com/company/madhav-techno", "_blank");
  };

  const handleLinkClickskype = () => {
    window.open('skype:live:.cid.a2ef0ee998d7527f?chat', '_blank');
  }

  return (
    <>
      <div>
        <div className="footer_bg1">
          <div className="footer_bg2">
            <Container className="footer_main">
              <Row>
                <Col lg={5} xs={12} md={12} className={"mb-4"}>
                  <img src={footerLogo} alt="footer" width={200} height={60} />
                  <div>
                    <div className="footer_des_text">
                      {allFooterDescriptions.nodes[0].data.attributes.description}
                    </div>
                    <div className="footer_des_text">
                      {allFooterDescriptions.nodes[0].data.attributes.description2}
                    </div>
                  </div>
                </Col>
                <Col lg={4} xs={12} md={6} className={"mb-4 witdh"}>
                  <div className="footer_title">Contact Us</div>
                  {allLocations.nodes[0].data.map((item, i) => {
                    return (
                      <div className="footer_location" key={i} onClick={() => openEmail(i)} style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <img src={imagePathURL + item.attributes.icon.data.attributes.url} alt="footer" />
                        {(i === 2)
                          ? <a style={{ color: 'white', textDecoration: 'none', width: '70%', textAlign: 'start' }} href="https://www.google.com/maps/place/City+Centre/@21.206154,72.8373404,21z/data=!4m15!1m8!3m7!1s0x3be04efbd2f1710d:0xf312ef8fb400d605!2sLal+Darwaja+Station+Rd,+Surat,+Gujarat!3b1!8m2!3d21.2068595!4d72.8401015!16s%2Fg%2F1tdr6n65!3m5!1s0x3be04ef0b4d10bf1:0xca9d2f2ae479108!8m2!3d21.2061587!4d72.8373126!16s%2Fg%2F1pzwl8msx?authuser=0&entry=ttu" target="blank">{item.attributes.name}</a>
                          : <div className="footer_loc_text" style={{ textDecoration: item.attributes.name === "info@MadhavTechnolgies.com" ? "underline" : "none" }}>{item.attributes.name}</div>
                        }
                      </div>
                    );
                  })}
                </Col>
                <Col lg={3} xs={12} md={6} className={"mb-4 witdh"}>
                  <div className="footer_social">
                    <div className="footer_title">Social</div>
                    {allSocials.nodes[0].data.map((item, i) => {
                      return (
                        <div className="socials" key={i} style={{ alignContent: 'flex-start', cursor: 'pointer' }}>
                          {/* {(i == 0) ?
                            <a href="https://www.linkedin.com/company/madhav-techno" target="blank" className="footer_social_text" style={{ color: 'white', textDecoration: 'none' }} >{item.attributes.name}</a>
                            : <div className="footer_social_text"> <a style={{ color: 'white', textDecoration: 'none' }} href="https://www.linkedin.com/company/madhav-techno" target="blank">{item.attributes.name}</a></div>
                          } */}
                          <div className="footer_linkedin">
                            <img className="footer_image_linkedin" src={Images.linkedin} alt="linkedin" onClick={handleLinkClick} />
                            <div className="footer_social_text" style={{ color: 'white', textDecoration: 'none', cursor: 'pointer' }} onClick={handleLinkClick} >
                              {item.attributes.name}
                            </div>
                            <div className="footer_skype" style={{ marginRight: '20px' }}>
                              <img className="footer_image_skype" src={Images.skypeblackicon} alt="linkedin" onClick={handleLinkClickskype} />
                              <div className="footer_social_text" style={{ color: 'white', textDecoration: 'none', cursor: 'pointer' }} onClick={handleLinkClickskype} >
                                {/* {item.attributes.name} */} Skype
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} >
                  <div className="d-flex footer_com_name">
                    <div className="footer_last_row_icon">©</div>
                    <div className="footer_last_row">
                      {`2023 MadhavTechnologies - Web & Mobile App Development
                      Company | Privacy | Sitemap`}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export const query = graphql`
  {
    allFooterDescriptions {
      nodes {
        data {
          attributes {
            description2
            description
          }
        }
      }
    }

    allLocations {
      nodes {
        data {
          attributes {
            name
            link
            icon {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }

    allSocials {
      nodes {
        data {
          attributes {
            name
            link
          }
        }
      }
    }
  }
`;

export default Footer;

